import React from 'react';

import css from './ImageBanner.module.css';

export const ImageBanner = ({ images }) => {
  const imageElements = images.map((image, idx) => {
    const imageTag = (
      <img
        key={idx}
        className={css.image}
        src={image.src}
        alt={image.alt}
      ></img>
    );
    return image.href ? <a href={image.href}>{imageTag}</a> : imageTag;
  });

  return <>{imageElements}</>;
};

ImageBanner.displayName = 'ImageBanner';
