import classNames from 'classnames';
import { throttle } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import DesktopNav from '../DesktopNav/DesktopNav';
import Social from '../../../base/components/Social/Social';

import css from './Header.module.css';
import { MobileNav } from '../MobileNav/MobileNav';

const logoClassHandler = (
  headerRef,
  logoRef,
  mobileIsOpen,
  showLogoOnScroll
) => {
  if (window.scrollY > 50) {
    headerRef.current?.classList.add(css.containerScroll);
    logoRef.current?.classList.add(css.logoScroll);
  } else {
    headerRef.current?.classList.remove(css.containerScroll);
    logoRef.current?.classList.remove(css.logoScroll);
  }

  if (showLogoOnScroll) {
    if (window.scrollY > 850 && !mobileIsOpen) {
      logoRef.current?.classList.remove(css.logoHide);
    } else if (window.scrollY < 850 || mobileIsOpen) {
      logoRef.current?.classList.add(css.logoHide);
    }
  }
};

export const Header = (props) => {
  const headerRef = useRef(null);
  const logoRef = useRef(null);
  const [mobileIsOpen, setMobileIsOpen] = useState(false);

  const { showLogoOnScroll } = props;
  useEffect(() => {
    window.addEventListener(
      'scroll',
      throttle(() => {
        logoClassHandler(headerRef, logoRef, mobileIsOpen, showLogoOnScroll);
      }, 100)
    );
  }, [mobileIsOpen, showLogoOnScroll]);

  useEffect(() => {
    logoClassHandler(headerRef, logoRef, mobileIsOpen, showLogoOnScroll);
  }, [mobileIsOpen, showLogoOnScroll]);

  return (
    <div ref={headerRef} className={css.container}>
      <button onClick={props.callbacks.onLogoClick}>
        <div
          ref={logoRef}
          className={classNames(css.logo, {
            [css.logoHide]: props.showLogoOnScroll || mobileIsOpen,
          })}
        />
      </button>
      <div className={css.links}>
        <DesktopNav callbacks={props.callbacks} />
        <Social />
      </div>
      <MobileNav isOpen={mobileIsOpen} setMobileIsOpen={setMobileIsOpen} />
    </div>
  );
};

Header.defaultProps = {
  showLogoOnScroll: false,
};
