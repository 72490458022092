import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';

import Social from '../../../base/components/Social/Social';

import css from './MobileNav.module.css';

export const MobileNav = ({ mobileIsOpen, setMobileIsOpen }) => {
  const [isOpen] = useState(false);

  const menuStyles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '1.25rem',
      height: '1rem',
      left: '1rem',
      top: '1rem',
    },
    bmBurgerBars: {
      background: '#ccc',
    },
    bmBurgerBarsHover: {
      background: 'purple !important',
    },
    bmCross: {
      background: '#ccc',
    },
    bmMenu: {
      background: '#085282',
      padding: '2.5em 1.5em 0',
      width: '200px',
    },
    bmMenuWrap: {
      width: '200px',
    },
    bmItemList: {
      padding: '0.8em 0.5rem 0 0.5rem',
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      fontSize: '1.5rem',
      textTransform: 'uppercase',
    },
    bmItem: {
      display: 'inline-block',
      paddingBottom: '2rem',
      textDecoration: 'none',
      fontFamily: 'Mountains of Christmas',
      fontWeight: '600',
      fontSize: '2rem',
    },
  };

  /*   const menuClassNames = {
    burgerButtonClassName: css.burgerButton,
    burgerBarClassName: css.burgerBar,
    burgerBarsHoverClassName: css.burgerBarsHover,
    crossClassName: css.cross,
    menuWrapClassName: css.menuWrap,
    menuClassName: css.menu,
    itemListClassName: css.itemList,
    itemClassName: css.item,
  }; */

  const onStateChange = (state) => {
    setMobileIsOpen(state.isOpen);
  };

  return (
    <div className={css.mobileNav}>
      <Menu
        styles={menuStyles}
        disableAutoFocus
        isOpen={isOpen}
        onStateChange={(state) => onStateChange(state)}
      >
        <a className={css.menuLink} href='/home#books'>
          Books
        </a>
        <a className={css.menuLink} href='/visits'>
          Workshops & Visits
        </a>
        <a className={css.menuLink} href='/contact'>
          Contact
        </a>
        {/* <a className={css.menuLink} href='/blog'>
          News
        </a> */}
        <a className={css.menuLink} href='/home#subscribe'>
          Subscribe
        </a>
        <a className={css.menuLink} href='/home#about'>
          About
        </a>
      </Menu>
      <span className={css.label}>MENU</span>
      <div className={css.social}>
        <Social />
      </div>
    </div>
  );
};
