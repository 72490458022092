import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

import css from './SubscribeForm.module.css';

const CustomForm = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthMonth, setBirthMonth] = useState('');
  const [birthDay, setBirthDay] = useState('');
  const [news, setNews] = useState(true);
  const [events, setEvents] = useState(true);
  const [giveaways, setGiveaways] = useState(true);

  useEffect(() => {
    if (status === 'success') clearFields();
  }, [status]);

  const clearFields = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const options = {
      ...(news && { 'group[31933][1]': '1' }),
      ...(events && { 'group[31933][2]': '1' }),
      ...(giveaways && { 'group[31933][4]': '1' }),
    };

    const forOnValidated = {
      EMAIL: email,
      MERGE1: firstName,
      MERGE2: lastName,
      'MERGE5[month]': birthMonth,
      'MERGE5[day]': birthDay,
      ...options,
    };

    email &&
      firstName &&
      lastName &&
      email.indexOf('@') > -1 &&
      onValidated(forOnValidated);
  };

  const toggleNews = () => {
    setNews(!news);
  };

  const toggleEvents = () => {
    setEvents(!events);
  };

  const toggleGiveaways = () => {
    setGiveaways(!giveaways);
  };

  return (
    <form className={css.form} onSubmit={(e) => handleSubmit(e)}>
      <h2 className={css.title}>
        {status === 'success'
          ? 'Thanks for subscribing, investigator!'
          : 'Join the Riverfish Valley Paranormal Society!'}
      </h2>
      <p>
        {status !== 'success'
          ? 'Subscribe to our mailing list to stay up to date on the latest news and events happening in the Thelmaverse!'
          : 'You should receive your initiation email shortly. Happy reading!'}
      </p>

      {status === 'success' ||
      (status === 'error' &&
        typeof message === 'string' &&
        message?.indexOf('already subscribed') !== -1) ? null : (
        <div className={css.fieldContainer}>
          <p>
            <label>
              First name
              <input
                onChange={(e) => setFirstName(e.target.value)}
                type='text'
                value={firstName}
                placeholder='Izzy'
                required={true}
              />
            </label>
            <span className={css.required}>(required)</span>
          </p>
          <p>
            <label>
              Last name
              <input
                onChange={(e) => setLastName(e.target.value)}
                type='text'
                value={lastName}
                placeholder='Finkle'
                required={true}
              />
            </label>
            <span className={css.required}>(required)</span>
          </p>
          <p>
            <label>
              Email
              <input
                onChange={(e) => setEmail(e.target.value)}
                type='email'
                value={email}
                placeholder='izzy@rvps.org'
                required={true}
              />
            </label>
            <span className={css.required}>(required)</span>
          </p>
          <p>
            <label>
              Birthday
              <input
                className={css.birth}
                onChange={(e) => setBirthMonth(e.target.value)}
                type='number'
                value={birthMonth}
                placeholder='MM'
                min={1}
                max={12}
                maxLength={2}
              />
              <input
                className={css.birth}
                onChange={(e) => setBirthDay(e.target.value)}
                type='number'
                value={birthDay}
                placeholder='DD'
                min={1}
                max={31}
                maxLength={2}
              />
            </label>
          </p>
          Send me this stuff:
          <ul>
            <li>
              <label htmlFor='group_1'>
                <input
                  onChange={() => toggleNews()}
                  type='checkbox'
                  id='group_1'
                  name='group[31933][1]'
                  value='1'
                  checked={news}
                />
                <span>News</span>
              </label>
            </li>
            <li>
              <label htmlFor='group_2'>
                <input
                  onChange={() => toggleEvents()}
                  type='checkbox'
                  id='group_2'
                  name='group[31933][2]'
                  value='1'
                  checked={events}
                />
                <span>Events</span>
              </label>
            </li>
            <li>
              <label htmlFor='group_4'>
                <input
                  onChange={() => toggleGiveaways()}
                  type='checkbox'
                  id='group_4'
                  name='group[31933][4]'
                  value='1'
                  checked={giveaways}
                />
                <span>Giveaways</span>
              </label>
            </li>
          </ul>
        </div>
      )}

      {status === 'success' ||
      (status === 'error' &&
        typeof message === 'string' &&
        message?.indexOf('already subscribed') !== -1) ? null : (
        <input className={css.submit} type='submit' value='Subscribe' />
      )}

      {status === 'sending' && <p className={css.mcMessaging}>Sending...</p>}
      {status === 'error' && (
        <p
          className={classNames(css.mcMessaging, css.error)}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
    </form>
  );
};

const SubscribeForm = () => {
  const postUrl = `${process.env.REACT_APP_MC_ACTION}?u=${process.env.REACT_APP_MC_USER}&id=${process.env.REACT_APP_MC_ID}`;

  return (
    <div className={css.formContainer}>
      <MailchimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
    </div>
  );
};

export default SubscribeForm;
