import React from 'react';
import css from './Workshop.module.css';

export const Workshop = ({ header, text, headerSize }) => {
  return (
    <div className={css.workshop}>
      {headerSize === 'large' ? <h3>{header}</h3> : <h4>{header}</h4>}
      {text}
    </div>
  );
};
