import css from './EventQuote.module.css';

export const EventQuote = () => (
  <div className={css.quoteCard}>
    <blockquote>
      <q>
        Erin's workshops{' '}
        <span className={css.mountains}>always inspire kids</span> to step into
        their imaginations and start writing their stories! She's patient and
        kind with each attendee, ready to encourage and make the hour{' '}
        <span className={css.mountains}>
          a positive experience that will stick
        </span>{' '}
        with our young patrons for a long time.
      </q>
    </blockquote>
    <p className={css.attribution}>
      — Melissa McCleary, Youth Services Librarian, Pembroke Public Library
    </p>
  </div>
);

EventQuote.displayName = 'EventQuote';
