import classNames from 'classnames';
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { Header } from '../../base/components/Header/Header';
import { ImageBanner } from '../../base/components/ImageBanner/ImageBanner';
import { MeadowBackground } from '../../base/components/MeadowBackground/MeadowBackground';
import SubscribeForm from '../../base/components/SubscribeForm/SubscribeForm';
import { About } from '../about/About';
import { BookBlurb } from '../books/BookBlurb';
import { BuyBookButtons } from '../books/BuyBookButtons';
import { Praise } from '../books/Praise';
import { EventQuote } from '../events/EventQuote';
import { Events } from '../events/Events';
import { ThelmaCovers } from '../thelmaCovers/ThelmaCovers';

import css from './Home.module.css';

import speaking from '../../assets/Petti-author-speaking.jpg';
import sticker from '../../assets/Petti-author-thelma-sticker.jpg';
import blogInterviews from '../../assets/blog-Interviews.jpg';
import logo from '../../assets/epb-white-web.png';
import brina from '../../assets/garden-wall.png';
import authorPhoto from '../../assets/new-author-photo2.png';
import octoberEvents from '../../assets/october-events.jpg';
import withChicken from '../../assets/petti-author-with-chicken.jpg';
import tb2Map from '../../assets/tb2-map-image.jpg';
import kraken from '../../assets/thelma-ig-kraken.jpeg';

export const Home = () => {
  const logoRef = useRef(null);
  const booksRef = useRef(null);
  const newsRef = useRef(null);
  const eventsRef = useRef(null);
  const subscribeRef = useRef(null);
  const aboutRef = useRef(null);
  const history = useHistory();

  const onLogoClick = (event) => {
    event.preventDefault();
    logoRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const onBooksClick = (event) => {
    event.preventDefault();
    booksRef.current.scrollIntoView({ behavior: 'smooth' });
    document.getElementsByTagName('body')[0].scrollTop -= 500; // Doesn't work. What's the scroll container?
  };

  const onContactClick = (event) => {
    window.location = '/contact';
  };

  const onEventsClick = () => {
    window.location = '/visits';
  };

  const onNewsClick = () => {
    window.location = '/blog';
  };

  const onBloodbonesClick = (event) => {
    event.preventDefault();
    history.push('/bloodbones');
  };

  const onSubscribeClick = (event) => {
    event.preventDefault();
    subscribeRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const onAboutClick = (event) => {
    event.preventDefault();
    aboutRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <MeadowBackground />
      <Header
        showLogoOnScroll={true}
        callbacks={{
          onLogoClick,
          onBooksClick,
          onAboutClick,
          onEventsClick,
          onBloodbonesClick,
          onSubscribeClick,
          onContactClick,
          onNewsClick,
        }}
      />
      <main id='main' className={css.mainContent}>
        <div className={css.booksContainer}>
          <div className={classNames(css.thelmaCoverScroll, css.sticky)}>
            <ThelmaCovers />
          </div>
          {/* end thelmaCoverScroll */}
          <div ref={logoRef} className={css.firstBatch}>
            <div className={css.heroImages}>
              <div className={css.logoDiv}>
                <img
                  className={css.logo}
                  src={logo}
                  alt={'Erin Petti Books logo'}
                />
              </div>
              <div className={css.thelmaCoverColumn}>
                <ThelmaCovers />
              </div>
            </div>
            {/* end logoDiv */}
            <div className={css.booksRef} ref={booksRef} id='books'>
              <div className={css.shadowBG}>
                <div className={css.thelma2BlurbContainer}>
                  <h2 className={css.thelma2Blurb}>
                    THELMA BEE in TOIL AND TREBLE available now!
                  </h2>
                  <h3 className={css.thelma2Subblurb}>
                    Buy TOIL AND TREBLE{' '}
                    <a
                      href='https://www.amazon.com/Thelma-Toil-Treble-Erin-Petti/dp/1952667674'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      here!
                    </a>
                  </h3>
                </div>
                <BookBlurb />
                <BuyBookButtons />
                <div className={css.imageBannerContainer}>
                  <ImageBanner
                    images={[
                      {
                        src: sticker,
                        alt: 'When we join together, we can do impossible things. Sometimes we can even bend worlds. Break magic.',
                      },
                      {
                        src: kraken,
                        alt: "What she saw then defied reason. The creature that emerged was nearly as wide as the river itself... One of the creature's white slimy claws held onto a large river rock. The other was clasped around Izzy's waist.",
                      },
                    ]}
                  />
                </div>
                <Praise full={false} />
                <div className={css.videoContainer}>
                  <iframe
                    title='Thelma Bee book trailer'
                    className={css.youtubePlayer}
                    src='https://www.youtube.com/embed/2Xgg-FsezG8?version=3&amp;rel=1&amp;showsearch=0&amp;showinfo=1&amp;iv_load_policy=1&amp;fs=1&amp;hl=en&amp;autohide=2&amp;wmode=transparent'
                    allowFullScreen={true}
                    sandbox='allow-scripts allow-same-origin allow-popups allow-presentation'
                    data-ratio='0.5627906976744186'
                    data-width='1075'
                    data-height='605'
                  ></iframe>
                  <iframe
                    title='Thelma Bee in Toil and Treble book trailer'
                    className={css.youtubePlayer}
                    src='https://www.youtube.com/embed/fv8juOcwFk4?version=3&amp;rel=1&amp;showsearch=0&amp;showinfo=1&amp;iv_load_policy=1&amp;fs=1&amp;hl=en&amp;autohide=2&amp;wmode=transparent'
                    allowFullScreen={true}
                    sandbox='allow-scripts allow-same-origin allow-popups allow-presentation'
                    data-ratio='0.5627906976744186'
                    data-width='1075'
                    data-height='605'
                  ></iframe>
                </div>
              </div>
            </div>
            {/* end booksRef */}
          </div>
          {/* end logoRef, first batch */}
        </div>
        {/* end booksContainer */}
        {/* <div ref={newsRef} id='news' className={css.news}>
          <h2>The Newest News</h2>
          <ul>
            <li key='1'>
              <article className={css.postCard}>
                <p className={css.date}>October 12th, 2022</p>
                <div className={css.postImageContainer}>
                  <a href={'/blog/ocbtober-book-events'}>
                    <img
                      src={octoberEvents}
                      width='451px'
                      alt={'October 2022 events'}
                    />
                  </a>
                </div>
                <div className={css.details}>
                  <h3 className={css.title}>
                    <a href={'/blog/ocbtober-book-events'}>October Events!</a>
                  </h3>
                  <p className={css.spoiler}>Lots of goings on!</p>
                </div>
              </article>
            </li>
            <li key='2'>
              <article className={css.postCard}>
                <p className={css.date}>September 2nd, 2022</p>
                <div className={css.postImageContainer}>
                  <a href={'/blog/mixed-up-files-and-mg-book-village-chats'}>
                    <img
                      src={blogInterviews}
                      alt={'Mixed Up Files and MG Book Village Chats'}
                    />
                  </a>
                </div>
                <div className={css.details}>
                  <h3 className={css.title}>
                    <a href={'/blog/mixed-up-files-and-mg-book-village-chats'}>
                      Mixed Up Files and MG Book Village Chats!
                    </a>
                  </h3>
                  <p className={css.spoiler}>
                    Read my recent interviews about Toil and Treble!
                  </p>
                </div>
              </article>
            </li>
            <li key='3'>
              <article className={css.postCard}>
                <p className={css.date}>August 20th, 2022</p>
                <div className={css.postImageContainer}>
                  <a href={'/blog/toil-and-treble-book-trailer'}>
                    <img
                      src={tb2Map}
                      alt={'Thelma Bee in Toil and Treble book trailer'}
                    />
                  </a>
                </div>
                <div className={css.details}>
                  <h3 className={css.title}>
                    <a href={'/blog/toil-and-treble-book-trailer'}>
                      Toil and Treble book trailer
                    </a>
                  </h3>
                  <p className={css.spoiler}>
                    The book trailer for Thelma Bee in Toil and Treble has
                    dropped, with a sneak peak of the map os Riverfish!
                  </p>
                </div>
              </article>
            </li>
          </ul>
        </div> */}
        <div ref={subscribeRef} id='subscribe' className={css.subscribe}>
          <SubscribeForm />
        </div>
        <div id='events' ref={eventsRef} className={css.eventsContainer}>
          <div className={classNames(css.eventsScroll, css.sticky)}>
            <EventQuote />
          </div>
          <div className={css.firstBatch}>
            <div className={css.shadowBG}>
              <Events />
            </div>
          </div>
        </div>
        <div id='author' ref={aboutRef}>
          <div className={classNames(css.sticky, css.authorPhotoContainer)}>
            <img
              className={css.authorPhoto}
              src={authorPhoto}
              alt={'Erin Petti, author'}
            />
          </div>
          <div className={css.firstBatch} id='about'>
            <div className={css.shadowBG}>
              <About showPhoto={false} />
              <div className={css.imageBannerContainer}>
                <ImageBanner
                  images={[
                    {
                      src: speaking,
                      alt: 'Erin speaking at a bookstore event',
                    },
                    {
                      src: withChicken,
                      alt: 'Erin holding a chicken',
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <footer className={css.brina}>
          <span>
            Garden wall illustration by{' '}
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.instagram.com/brinawilliamsillustration'
            >
              Brina Williams
            </a>
          </span>
          <div className={css.footer}>
            <img src={brina} alt={'The Garden Wall'} />
          </div>
        </footer>
      </main>
    </>
  );
};
