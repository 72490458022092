import thelma from '../../assets/thelma.webp';
import thelma2 from '../../assets/thelma2cover687.jpg';
import { Button } from '../../base/components/Button/Button';

import css from './ThelmaCovers.module.css';

export const ThelmaCovers = () => (
  <div className={css.coverContainer}>
    <div className={css.imgContainer}>
      <div className={css.filler}> </div>
      <img
        className={css.thelmaImg}
        src={thelma}
        alt={'Peculiar Haunting of Thelma Bee Cover'}
      />
      <Button text='Buy' link='https://www.indiebound.org/book/9781938063725' />
    </div>
    <div className={css.imgContainer}>
      <div className={css.new}>New!</div>
      <img
        className={css.thelma2Img}
        src={thelma2}
        alt={'Thelma Bee in Toil and Treble Cover'}
      />
      <Button
        text='Buy'
        link='https://www.amazon.com/Thelma-Toil-Treble-Erin-Petti/dp/1952667674'
      />
    </div>
  </div>
);
