import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import classNames from 'classnames';

import { Home } from '../../features/home/Home';
import { Container } from '../components/Container/Container';

import css from './AppContainer.module.css';
import ScrollToTop from './ScrollToTop/ScrollToTop';
import SubscribeForm from './SubscribeForm/SubscribeForm';
import { Visits } from '../../features/visits/Visits';
import { ContactForm } from './ContactForm/ContactForm';

export const AppContainer = () => {
  return (
    <div className={css.rootContainer}>
      <Router>
        <ScrollToTop>
          <Switch>
            <Route path='/join'>
              <Container>
                <div className={classNames(css.container, css.subscribe)}>
                  <SubscribeForm />
                </div>
              </Container>
            </Route>
            <Route path='/visits'>
              <Container>
                <div className={css.container}>
                  <Visits />
                </div>
              </Container>
            </Route>
            <Route path='/contact'>
              <Container>
                <div className={classNames(css.container, css.subscribe)}>
                  <ContactForm />
                </div>
              </Container>
              <div className={css.attrContainer}>
                <a
                  className={css.flowerAttr}
                  href='https://www.vecteezy.com/free-vector/sunflower-line-drawing'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  Sunflower Line Drawing Vector by Vecteezy
                </a>
              </div>
            </Route>
            <Route path='/'>
              <Home />
            </Route>
            <Route path='*'>
              <Home />
            </Route>
          </Switch>
        </ScrollToTop>
      </Router>
    </div>
  );
};

export default AppContainer;
