import React from 'react';

import css from './BuyBookButtons.module.css';

export const BuyBookButtons = () => {
  return (
    <>
      <h3 className={css.buy}>
        The Thelme Bee series now available on{' '}
        <a
          href='https://www.audible.com/author/Erin-Petti/B01CRC7U6K'
          target='_blank'
          rel='noopener noreferrer'
        >
          audiobook!
        </a>
      </h3>
    </>
  );
};
