import React from 'react';

import css from './BookBlurb.module.css';

export const BookBlurb = () => {
  return (
    <article className={css.description}>
      <p className={css.blurb}>
        Ghost-story lover and debut author Erin Petti has written a quirky story
        for fans of The Goonies, Goosebumps, and Coraline.
      </p>
      <p>
        Just when eleven-year-old Thelma Bee thinks she might finish 6th grade
        like a semi-normal kid, supernatural activity begins to spike once again
        in Riverfish, MA. From reports of wandering tree monsters, to a local
        river shifting its course, something is weaving magical chaos -- and
        this time it's all by deadly design. When her school's a capella group
        starts acting suspicious, and a classmate goes missing, Thelma learns
        that sometimes dark magic comes in the most unexpected packages. Now,
        with her hometown on the line, Thelma must harness the ancient power
        inside her to heal the wounds of a dark local history and save the
        people she loves--she just has to figure out how.
      </p>
    </article>
  );
};
