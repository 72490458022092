import React from 'react';

import css from './Events.module.css';

import youngWritera from '../../assets/youngwriters22.jpg';
import { EventQuote } from './EventQuote';

export const Events = ({ showPhotos }) => {
  return (
    <div className={css.container}>
      <div className={css.quoteContent}>
        <h2>Workshops and Visits</h2>
        <div className={css.quoteContainer}>
          <EventQuote />
        </div>
        <p>
          I am available for visits and love speaking with young readers! I can
          also customize creative writing workshops for students, scout groups,
          libraries, and book clubs. Here are just some of the workshops I
          offer:
        </p>
        <ul>
          <li>Write Your Own Spooky Story</li>
          <li>Capturing Voice: The Art of the Monologue</li>
          <li>Customized School/Library Workshops</li>
          <li>Author Talk / Q&amp;A: What's Your Superpower</li>
        </ul>
        <p>
          For more details, visit my <a href='/visits'>Author Visits</a> page.
        </p>
        <p>
          Email me at{' '}
          <span className={css.email}>erinpettiauthor@gmail.com</span> to
          inquire about availability and pricing!
        </p>
        {showPhotos && (
          <div className={css.photos}>
            <img src={youngWritera} alt='A recent crew of young writers' />
          </div>
        )}
      </div>

      {/*<h2>Upcoming Events</h2>
      <div className={css.eventContainer}>
        <article className={css.eventCard}>
          <div className={css.details}>
            <h3 className={css.date}>November 1st, 2021</h3>
            <p className={css.title}>
              <EventLink href='/contest'>THELMA 2 Art Contest</EventLink>
            </p>
            <p className={css.description}>
              <EventLink href='/contest'>
                <img src={contest} alt='Art contest card for Thelma 2' />
              </EventLink>
              Get your Thelma art published!
            </p>
          </div>
        </article>
        <article className={css.eventCard}>
          <div className={css.details}>
            <h3 className={css.date}>December 11th, 3pm - 8pm</h3>
            <p className={css.title}>Salt House Winter Market</p>
            <p className={css.description}>
              <img src={saltFair} alt='Salt House Winter Market' />
              This Saturday I'll be at the Salt House Winter Market. Come by and
              grab a signed and wrapped copy of Thelma for the reader on your
              list!
            </p>
          </div>
        </article>
      </div>*/}
    </div>
  );
};

Events.defaultProps = {
  showPhotos: true,
};
