import React from 'react';

import css from './Praise.module.css';

export const Praise = ({ full }) => {
  return (
    <article className={css.quotes}>
      <h3>Praise for THELMA BEE</h3>
      <div className={css.quoteCard}>
        <blockquote>
          <q>
            ...a smart, fun, and{' '}
            <span className={css.mountains}>inventive adventure</span> down a
            fantastical rabbit-hole
          </q>
        </blockquote>
        <p className={css.attribution}>
          — Neil Reynolds, writer for The CW's The Vampire Diaries
        </p>
      </div>
      <p>
        "Petti steadily increases a sense of suspense and dread. The worlds she
        creates, especially the ones inside the box, are{' '}
        <span className={css.mountains}>imaginative and fresh</span>. It is
        rewarding to read about a scientifically minded female protagonist, and
        that should help attract an audience. VERDICT Those looking for a
        chilling but quick read should enjoy this one, as will reluctant
        readers. Recommended for most middle grade collections." —
        <a
          href='https://www.slj.com/?reviewDetail=the-peculiar-haunting-of-thelma-bee'
          target='_blank'
          rel='noopener noreferrer'
        >
          <span className={css.source}>School Library Journal</span>
        </a>
      </p>
      <p>
        "Black-and-white illustrations add visual dimension to Thelma’s peculiar
        haunting, punctuated by secrets from the past, paranormal happenings,{' '}
        <span className={css.mountains}>diverse and devoted friends</span>, and
        a <span className={css.mountains}>fiery finale</span>. Such humorous
        details as a car named Gary Indiana and the bickering of Thelma’s
        friends in the Riverfish Valley Paranormal Society keep the tone light.
        An inventive debut ghost story starring a contemporary girl with ancient
        powers."{' '}
        <span className={css.source}>
          —
          <a
            href='https://www.kirkusreviews.com/book-reviews/erin-petti/the-peculiar-haunting-of-thelma-bee/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Kirkus Reviews
          </a>
        </span>
      </p>
      {full && (
        <>
          <p>
            <span className={css.title}>
              The Peculiar Haunting of Thelma Bee
            </span>{' '}
            is a delightfully scary story about a bright young girl with a
            curious mind and an adventurous spirit. When Thelma’s dad is
            kidnapped by a ghost, she must find a way to get him back. The book
            is filled with likeable characters and interesting settings, brought
            to life by simple black-and-white drawings scattered throughout the
            text. Children ages eight to twelve will be thrilled with an
            engaging story that is full of twists and surprises. —
            <a
              href='https://www.forewordreviews.com/reviews/the-peculiar-haunting-of-thelma-bee/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <span className={css.source}>Foreword Reviews</span>
            </a>
          </p>
          <p>
            "Thelma is a smart, independent character who quickly takes control
            of the situation and her dislike for the annoying popular girls will
            endear her to many readers. This supernatural thriller is a must for
            those who enjoy the similarly chilling books by Mary Downing Hahn
            and Dan Poblocki." —
            <a
              href='https://sanfranciscobookreview.com/product/the-peculiar-haunting-of-thelma-bee/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <span className={css.source}>San Fransisco Book Review</span>
            </a>
          </p>
          <p>
            <span className={css.emphasis}>
              "The thing I like most about Thelma Bee is the way she views the
              world.
            </span>{' '}
            Whether she’s planting exotic flowers or chasing ghosts through the
            kitchen, she sees things as a (proudly) smart, young scientist and
            as a hopeful, starry-eyed dreamer. Hypothesis: she’ll steal lots of
            hearts."{' '}
            <span className={css.emphasis}>
              —Natalie Lloyd, New York Times bestselling author of{' '}
              <span className={css.title}>A Snicker of Magic</span> and{' '}
              <span className={css.title}>The Key to Extraordinary</span>
            </span>
          </p>

          <p>
            "A full-of-surprises heroine, her intrepid friends, ghosts and
            monsters, dark wizards and good witches, mad bulls and wild cats, a
            haunted jewelry box and spooky goo—there's something for everyone in
            this rollicking fun read!" —Margi Preus, author of Newbery Honor
            winner{' '}
            <span className={css.title}>
              Heart of a Samurai and Enchantment Lake
            </span>
          </p>
          <p>
            “Thelma Bee is the girl I always wanted to be friends with in
            school: a smart, funny, chatty little scientist who'd be endlessly
            entertaining even if she weren't developing supernatural powers.
            Likewise,{' '}
            <span className={css.title}>
              The Peculiar Haunting of Thelma Bee
            </span>{' '}
            is{' '}
            <span className={css.emphasis}>
              a smart, fun, and inventive adventure down a fantastical
              rabbit-hole.
            </span>{' '}
            Thelma's world is brimming with wit, colorful characters, and
            unexpected thrills. (And magic, of course.) —Neil Reynolds,{' '}
            <span className={css.emphasis}>
              writer for The CW's{' '}
              <span className={css.title}>The Vampire Diaries</span>
            </span>
          </p>
          <p>
            “<span className={css.title}>Thelma Bee</span>{' '}
            <span className={css.emphasis}>
              takes place at the intersection of science, magic, and mystery.
              It's engaging, clever, and suspenseful.
            </span>{' '}
            I only wish I could time travel and give it to myself at ten. This
            one is definitely going on my daughter's bookshelf!” —Annie Mebane,{' '}
            <span className={css.emphasis}>
              writer for ABC’s <span className={css.title}>The Goldbergs</span>,
              NBC’s <span className={css.title}>Community</span>, and ABC’s{' '}
              <span className={css.title}>Happy Endings</span>
            </span>
          </p>
          <p>
            "A fast, engaging read, this book should entertain and charm readers
            everywhere.{' '}
            <span className={css.title}>
              The Peculiar Haunting of Thelma Bee
            </span>{' '}
            is a clever, imaginative book with wonderful characters that has the
            real potential to become a huge hit. ...Young women should really
            relate to this magical book and be swept up into its enchanted,
            albeit slightly frightening, world... If you love{' '}
            <span className={css.title}>Coraline</span>,{' '}
            <span className={css.title}>Matilda</span>,{' '}
            <span className={css.title}>Harry Potter</span>,{' '}
            <span className={css.title}>The Secret Circle</span>, or{' '}
            <span className={css.title}>Ghostbusters</span>, then this is the
            book for you." —The Silver Petticoat Review
          </p>
          <p>
            "I couldn’t get enough of [Thelma's] endless curiosity and need to
            learn new things." —Bookish Babes
          </p>
          <p>
            "Thelma is a great creation ... If you like spooky stuff, this is a
            book for you!" —Middle Grade Mafioso
          </p>
          <p>
            "Even though{' '}
            <span className={css.title}>
              The Peculiar Haunting of Thelma Bee
            </span>{' '}
            is a ghost story, it's also a story of a brave girl who uses her
            scientific skills to her advantage." —Teach Mentor Texts
          </p>
          <p>
            "Starring a charming and quirky budding scientist and featuring an
            exciting can't-put-it-down plot,{' '}
            <span className={css.title}>
              The Peculiar Haunting of Thelma Bee
            </span>{' '}
            had me cheering all the way through!” —Sarah Beth Durst, author of{' '}
            <span className={css.title}>The Girl Who Could Not Dream</span> and{' '}
            <span className={css.title}>Vessel</span>
          </p>
          <p>
            "Middle graders are going to love the smart, spunky middle grader
            that doesn’t care about the mean girls and their dopey fashion
            choices – she has better things to do. ... Put this one right next
            to Neil Gaiman’s <span className={css.title}>Coraline</span>, Sally
            Gardner’s <span className={css.title}>Wings & Co.</span> series, and
            Karen Foxlee’s{' '}
            <span className={css.title}>Ophelia and the Marvelous Boy</span> to
            create a solid girl-power reading display." —Mom Read It
          </p>
          <p>
            "This is a fun, fast-paced read for those who like their ghosts just
            a little bit scary. It’s a promising beginning to an exciting new
            series. It is a must read." —Middle Grade Mafia
          </p>
          <p>
            "...the storyline was well-crafted with a great spin on N orse
            mythology and played off a real historical event. The fast-pacing
            kept me entertained from start to finish. Thelma and her friends
            were a fun bunch that I loved following on this adventure!" —Stories
            & Sweeties
          </p>
        </>
      )}
    </article>
  );
};

Praise.defaultProps = {
  full: true,
};
