import css from './ContactForm.module.css';
import React, { useState } from 'react';
import flower from '../../../assets/flower.png';
import classNames from 'classnames';

const statuses = {
  notSent: 'Send',
  pending: 'Sending...',
  done: 'Email sent.',
  error: "Oops! That didn't work.",
};

export const ContactForm = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState(statuses.notSent);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (status !== statuses.notSent) return;
    setStatus(statuses.pending);
    const { name, email, message, subject } = e.target.elements;
    let details = {
      name: name.value,
      email: email.value,
      subject: subject.value,
      message: message.value,
    };
    let response = await fetch(process.env.REACT_APP_MAILER_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_MAILER_JWT}`,
      },
      body: JSON.stringify(details),
    });
    response.ok ? setStatus(statuses.done) : setStatus(statuses.error);
  };

  return (
    <div className={css.contactContainer}>
      <div className={css.formContainer}>
        <h2>Fill out the form below to reach me:</h2>
        <form className={css.form} onSubmit={(e) => handleSubmit(e)}>
          <div className={css.fieldContainer}>
            <p>
              <label>
                Your name
                <input
                  onChange={(e) => setName(e.target.value)}
                  type='text'
                  id='name'
                  value={name}
                  placeholder='Izzy Finkle'
                  required={true}
                />
              </label>
              <span className={css.required}>(required)</span>
            </p>
            <p>
              <label>
                Your email address
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  type='email'
                  id='email'
                  value={email}
                  placeholder='izzy@rvps.org'
                  required={true}
                />
              </label>
              <span className={css.required}>(required)</span>
            </p>
            <p>
              <label>
                Subject
                <input
                  className={css.subject}
                  onChange={(e) => setSubject(e.target.value)}
                  type='text'
                  id='subject'
                  value={subject}
                  placeholder='Important transmission!'
                  required={true}
                />
              </label>
              <span className={css.required}>(required)</span>
            </p>
            <p>
              <label className={css.messageLabel}>
                Message
                <textarea
                  onChange={(e) => setMessage(e.target.value)}
                  type='text'
                  id='message'
                  value={message}
                  placeholder='Your books are great!'
                  required={true}
                />
              </label>
              <span className={css.required}>(required)</span>
            </p>
          </div>
          <input
            className={classNames(css.submit, {
              [css.disable]: status !== statuses.notSent,
              [css.done]: status === statuses.done || status === statuses.error,
            })}
            type='submit'
            value={status}
          />
          {status === statuses.done ? (
            <p>Thanks for getting in touch!</p>
          ) : status === statuses.error ? (
            <p>Try refreshing the page and submitting again.</p>
          ) : null}
        </form>
      </div>
      <div className={css.agentContainer}>
        <h2>For Blurbs or Rights Inquiries contact:</h2>
        <p>Jonathan Rosen, Literary Agent</p>
        <p>The Seymour Agency</p>
        <p>
          <a href='mailto:jonathan@theseymouragency.com'>
            jonathan@theseymouragency.com
          </a>
        </p>
        <img alt='An line drawing of a sunflower' src={flower} />
      </div>
    </div>
  );
};
