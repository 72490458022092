import css from './Button.module.css';

export const Button = ({ text, link, external = true }) => {
  return external ? (
    <a
      className={css.button}
      href={link}
      target='_blank'
      rel='noopener noreferrer'
    >
      {text}
    </a>
  ) : (
    <a className={css.button} href={link}>
      {text}
    </a>
  );
};
