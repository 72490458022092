import classNames from 'classnames';
import reactStringReplace from 'react-string-replace';
import css from './Testimonial.module.css';

export const Testimonial = ({
  quote,
  name,
  title,
  institution,
  topQuote = false,
}) => {
  const quoteFormatted = reactStringReplace(
    quote,
    /<hl>([A-Za-z\s!]*)<\/hl>/g,
    (match, i) => (
      <span key={i} className={css.highlight}>
        {match}
      </span>
    )
  );
  console.log({ quoteFormatted });
  return (
    <div className={classNames(css.testimonial, { [css.block]: !topQuote })}>
      {!topQuote && <div className={css.quotation}>&ldquo;</div>}
      <div className={classNames(css.quote, { [css.topQuote]: topQuote })}>
        {quoteFormatted}
      </div>
      <div
        className={classNames(css.attribution, { [css.topQuote]: topQuote })}
      >
        <div className={css.title}>
          <p>{name}</p>
          {title && <p>{title}</p>} {institution && <p>{institution}</p>}
        </div>
      </div>
    </div>
  );
};
