import classNames from 'classnames';
import css from './Visits.module.css';

import { Workshop } from './Workshop';

import bookWorkshop1 from '../../assets/book-writing-workshop1.jpg';
import bookWorkshop3 from '../../assets/book-writing-workshop3.jpg';
import ventress from '../../assets/ventressWorkshop1.jpg';
import writers from '../../assets/youngwriters22.jpg';
import inPeron from '../../assets/Petti-author-in-person-visit.jpg';
import presentation from '../../assets/classroom-preso.jpg';
import branch from '../../assets/long-divider.png';
import { Testimonial } from './Testimonial';
import { Button } from '../../base/components/Button/Button';

export const Visits = () => {
  return (
    <div className={css.container}>
      <div className={css.outerHeader}>
        <div className={css.header}>
          <h1>Workshops & Visits</h1>
          <h2>with Erin Petti, author of the Thelma Bee series</h2>
        </div>
      </div>
      <div className={css.workshopContainer}>
        <div className={css.topTestimonial}>
          <Testimonial
            topQuote={true}
            quote='"We truly believe that Erin’s visit will have
            <hl>a long lasting impact</hl> on our student’s approach to storytelling. We look forward to inviting her back to our school for
            <hl>many years to come</hl>."'
            name='Jessica Shaw'
            institution='Halifax Elementary School'
          />
          <div className={css.divider}>
            <img className={css.dividerImage} src={branch} alt='page divider' />
          </div>
        </div>
        <article>
          <div className={css.workshops}>
            <div className={css.column1}>
              <Workshop
                header='Write Your Own Story'
                text='Students create their own heroes, monsters, and explore the
                vital question: What makes a good story? By the end they are on
                their way to building a world of their own. This hour-long class
                session is designed to spark inspiration and creativity.'
              />
              <img
                className={css.ventress}
                src={ventress}
                alt='A student writing her own story.'
              />
            </div>
            <div className={css.column2}>
              <Workshop
                header='Customized School / Library Workshops'
                text='I am happy to work
                individually with schools, libraries, homeschool co-ops, and
                other groups to meet your needs! I&rsquo;ve developed multi-week
                “Write Your Own Book” programs, and ultra-condensed “Sparking
                Creativity” sessions. If your school or library would like to
                customize a workshop, please get in touch!'
              />
              <Workshop
                header='Author Talk / Q&A'
                text='This session can be for classrooms or for the whole grade level. We
                will discuss what it takes to write a book, why creativity matters,
                and my own path to publication. Key themes include resilience,
                perseverance, the importance of asking great questions, and most of
                all - letting your own unique light shine.'
              />
              <div className={css.contactButton}>
                <Button text='Contact' link='/contact' external={false} />
              </div>
            </div>
          </div>
        </article>
        <img
          className={css.writerGroup}
          src={writers}
          alt='A group of young writers showing off their hard work!'
        />
        <div className={css.imageColumns}>
          <img
            className={css.midImage}
            src={inPeron}
            alt='Visiting a grade level in person'
          />
          <img
            className={css.midImage}
            src={presentation}
            alt='A classroom presentation'
          />
        </div>
        <div className={css.divider}>
          <img className={css.dividerImage} src={branch} alt='page divider' />
        </div>
        <article>
          <div className={classNames(css.testimonials, css.column1)}>
            <h3>Testimonials</h3>
            <div className={css.testimonialContainer}>
              <div className={css.testimonialCol1}>
                <Testimonial
                  quote='Our program was a huge success thanks to Erin! She was
                  <hl>energetic and imaginative</hl> and always ready to figure
                  out the best way to work with every child in the group. Erin
                  has the most <hl>wonderfully enthusiastic</hl> approach to
                  every class and child. I am so glad that I found her!'
                  name='Wendy Gardner-Breindel'
                  title='Head of Youth Services'
                  institution='Ventress Memorial Library'
                />
                <Testimonial
                  quote='Being unable to read or write independently due to medical
                    issues is usually a huge roadblock but
                    <hl>
                      this class helped him see that he can create wonderful
                      stories
                    </hl>
                    despite his challenges.'
                  name='Melanie, parent, Townsend MA'
                />
                <Testimonial
                  quote='[My child] LOVED this program and
                    <hl>loved working with Erin</hl>. She was wonderful with
                    the kids and her positive attitude
                    was perfect for the role!
                    <hl>
                      I hope to see her back soon
                    </hl>
                    to work with the kids more!'
                  name='Parent, Marshfield MA'
                />
                <img
                  className={css.workshop3}
                  alt='Young author of Ghost Detectives at Townsend Public Library'
                  src={bookWorkshop3}
                />
              </div>
              <div className={css.testimonialCol2}>
                <Testimonial
                  quote='Erin’s workshops <hl>always inspire kids</hl>
                    to step into their imaginations and start writing their
                    stories! She’s patient and kind with each attendee, ready to
                    encourage and make the hour a
                    <hl>positive experience</hl>
                    that will stick with our young patrons for a long time.'
                  name='Melissa McCleary'
                  title='Youth Services Librarian'
                  institution='Pembroke Public Library'
                />
                <img
                  className={css.workshop1}
                  alt='Young author of The Time Machine at Townsend Public Library'
                  src={bookWorkshop1}
                />

                <Testimonial
                  quote='...his <hl>confidence has soared and his focus has improved</hl>;
                    where as before he struggled reading and wasn’t interested
                    in writing at all.'
                  name='Parent, Marshfield MA'
                />
                <Testimonial
                  quote='
                    What an
                    <hl>amazing opportunity</hl>
                    this was...Erin Petti was amazing, patient, and so
                    informative with these kids! She really made these kids dig
                    deep to
                    <hl>
                      give their best possible!
                    </hl>
                    '
                  name='Amber, parent, Townsend MA'
                />
              </div>
            </div>
          </div>
        </article>
      </div>
      <a
        className={css.floralCredit}
        href='https://www.vecteezy.com/free-vector/floral'
        target='_blank'
        rel='noopener noreferrer'
      >
        Floral Vectors by Vecteezy
      </a>
    </div>
  );
};
