import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import css from './Container.module.css';
import { Header } from '../Header/Header';
import { MeadowBackground } from '../MeadowBackground/MeadowBackground';

export const Container = ({ children }) => {
  const history = useHistory();

  const onLogoClick = (event) => {
    event.preventDefault();
    window.location = '/';
  };

  const onBooksClick = (event) => {
    event.preventDefault();
    window.location = '/home#books';
  };

  const onEventsClick = (event) => {
    event.preventDefault();
    window.location = '/visits';
  };

  const onBloodbonesClick = (event) => {
    history.push('/bloodbones');
  };

  const onSubscribeClick = (event) => {
    event.preventDefault();
    window.location = '/home#subscribe';
  };

  const onContactClick = (event) => {
    event.preventDefault();
    window.location = '/contact';
  };

  const onAboutClick = (event) => {
    event.preventDefault();
    window.location = '/home#about';
  };

  return (
    <>
      <MeadowBackground />
      <main className={css.container}>
        <Header
          callbacks={{
            onLogoClick,
            onBooksClick,
            onEventsClick,
            onBloodbonesClick,
            onSubscribeClick,
            onContactClick,
            onAboutClick,
          }}
        />
        {children}
      </main>
    </>
  );
};

Container.propTypes = {
  children: PropTypes.node,
};
