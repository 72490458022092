import React from 'react';

import css from './DesktopNav.module.css';

export const DesktopNav = ({ callbacks }) => {
  return (
    <nav className={css.desktopNav}>
      <ol>
        <li>
          <button onClick={callbacks?.onBooksClick}>Books</button>
        </li>
        <li>
          <button onClick={callbacks?.onEventsClick}>Workshops & Visits</button>
        </li>
        <li>
          <button onClick={callbacks?.onContactClick}>Contact</button>
        </li>
        {/* <li>
          <button onClick={callbacks?.onNewsClick}>News</button>
        </li> */}
        <li>
          <button onClick={callbacks?.onSubscribeClick}>Subscribe</button>
        </li>
        <li>
          <button onClick={callbacks?.onAboutClick}>About</button>
        </li>
      </ol>
    </nav>
  );
};

export default DesktopNav;
