import React from 'react';

import authorPhoto from '../../assets/new-author-photo2.png';
import speaking from '../../assets/Petti-author-speaking.jpg';
import withChicken from '../../assets/petti-author-with-chicken.jpg';
import thelmaSticker from '../../assets/Petti-author-thelma-sticker.jpg';

import css from './About.module.css';

export const About = ({ showPhotos }) => {
  return (
    <>
      <div className={css.top}>
        <div className={css.left}>
          <article>
            <div className={css.authorPhotoContainer}>
              <img
                src={authorPhoto}
                alt='Erin Petti'
                className={css.authorPhoto}
              ></img>
            </div>
            <h2>About Erin</h2>
            <p>
              I’m a writer who specializes in stories for young people. I live
              in New England with my husband, two daughters, a sweet dog, a
              murderous cat, and five chickens who defy description. I’m the
              author of THE PECULIAR HAUNTING OF THELMA BEE.
            </p>
            <h4>Things I really love</h4>
            <ul>
              <li>Ghost stories</li>
              <li>Garden smells</li>
              <li>Watching live theater</li>
              <li>Practicing yoga</li>
              <li>
                When Peter Jackson cameos in the LOTR movies, especially when
                he’s eating a carrot
              </li>
            </ul>
          </article>
        </div>
      </div>
      {showPhotos && (
        <div className={css.photos}>
          <img src={speaking} alt='Erin speaking at a bookstore event' />
          <img src={withChicken} alt='Erin holding a chicken' />
          <img src={thelmaSticker} alt='Break magic #HauntingThelma' />
        </div>
      )}
    </>
  );
};

About.defaultProps = {
  showPhoto: true,
};
